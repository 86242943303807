import { getApplicableUsoCfdi } from './getApplicableUsoCfdi'

export const checkIsValidUsoCfdi = ({
    taxSystem,
    cfdiUse,
}: {
    taxSystem: string | number
    cfdiUse: string
}) => {
    const applicableUsoCfdi = getApplicableUsoCfdi(taxSystem)
    return applicableUsoCfdi.some((item) => item.code === cfdiUse)
}
